// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("gradienti.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.banner_backroundImage__2trvc{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Replace with your image path */
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    height: 600px !important; /* Makes the container full height */
    display: flex;
    align-items: center;
    padding: 40px !important;
    justify-content: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}


@media screen and (max-width: 700px) {

 .banner_main__owHsI{
    height: 600px !important;
 }

 .banner_backroundImage__2trvc{
    padding-top: 200px !important;
    height: 600px !important; /* Makes the container full height */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* height: 400px !important; */
 }

    
}

.banner_buton__O9-G9{
    margin-top: 3%;
}

/* 
.main{
     margin-top:220px !important;
     padding-top: 220px !important;
     
} */


@media screen and (max-width: 960px) {

    /* .main{
        position:absolute !important;
        width:100% !important;
        top:0 !important;   } */
    
}

#banner_banner__O9pfC {
    margin-top: 100px; /* Adjust as needed based on your navbar height */
    /* Other carousel styles */
}`, "",{"version":3,"sources":["webpack://./src/components/Banner/banner.module.css"],"names":[],"mappings":";;AAEA;IACI,yDAAsC,EAAE,iCAAiC;IACzE,sBAAsB,EAAE,kDAAkD;IAC1E,2BAA2B,EAAE,sBAAsB;IACnD,4BAA4B,EAAE,sCAAsC;IACpE,wBAAwB,EAAE,oCAAoC;IAC9D,aAAa;IACb,mBAAmB;IACnB,wBAAwB;IACxB,uBAAuB;IACvB,2CAA2C;AAC/C;;;AAGA;;CAEC;IACG,wBAAwB;CAC3B;;CAEA;IACG,6BAA6B;IAC7B,wBAAwB,EAAE,oCAAoC;IAC9D,wBAAwB;IACxB,kCAAkC;IAClC,8BAA8B;IAC9B,8BAA8B;CACjC;;;AAGD;;AAEA;IACI,cAAc;AAClB;;AAEA;;;;;GAKG;;;AAGH;;IAEI;;;+BAG2B;;AAE/B;;AAEA;IACI,iBAAiB,EAAE,iDAAiD;IACpE,0BAA0B;AAC9B","sourcesContent":["\n\n.backroundImage{\n    background-image: url('gradienti.png'); /* Replace with your image path */\n    background-size: cover; /* Ensures the image covers the entire container */\n    background-position: center; /* Centers the image */\n    background-repeat: no-repeat; /* Prevents the image from repeating */\n    height: 600px !important; /* Makes the container full height */\n    display: flex;\n    align-items: center;\n    padding: 40px !important;\n    justify-content: center;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);\n}\n\n\n@media screen and (max-width: 700px) {\n\n .main{\n    height: 600px !important;\n }\n\n .backroundImage{\n    padding-top: 200px !important;\n    height: 600px !important; /* Makes the container full height */\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n    /* height: 400px !important; */\n }\n\n    \n}\n\n.buton{\n    margin-top: 3%;\n}\n\n/* \n.main{\n     margin-top:220px !important;\n     padding-top: 220px !important;\n     \n} */\n\n\n@media screen and (max-width: 960px) {\n\n    /* .main{\n        position:absolute !important;\n        width:100% !important;\n        top:0 !important;   } */\n    \n}\n\n#banner {\n    margin-top: 100px; /* Adjust as needed based on your navbar height */\n    /* Other carousel styles */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backroundImage": `banner_backroundImage__2trvc`,
	"main": `banner_main__owHsI`,
	"buton": `banner_buton__O9-G9`,
	"banner": `banner_banner__O9pfC`
};
export default ___CSS_LOADER_EXPORT___;
