import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "./SpecificProduct.module.css";
import axios, { all, spread } from "axios";
import { useCookies } from "react-cookie";
import productsOnDashboard from "../../Data/ProductsOnDashboard";
import personalization from "../../Data/Personalization";
import { Cookies } from "../../Functions/Cookies";
import { useFormik } from "formik";
// import { DeleteSpecificProduct } from "../../Functions/Product/DeleteSpecificProduct";
import Modal from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaBorderAll } from "react-icons/fa";
import { object } from "yup";
import LoadingIndicator from 'react-loading-indicator';

export default function SpecificProduct({ updateProductToDashboard }) {
    var personalizedId = 1
    const [isProductInOffer, setIsProductInOffer] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const params = useParams();
    const [largeImage, setLargeImage] = useState(0);
    const [specificProduct, setSpecificProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    var chProducts = [];
    const [changedProducts, setChangedProduct] = useState([]);
    const imageArray = specificProduct?.ProductImages?.split(",");
    let id = parseInt(params.id);
    const [raitValue, setRaitValue] = useState(0)
    const [raitComment, setRaitComment] = useState([])
    const [fontName, setFontName] = useState("Times New Roman")
    const [name, setName] = useState("")
    var cookies = Cookies();
    var token = cookies["token"]
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed
    var prodCategory = ["Ore", "Byzylyk", "Unaza", "Varse"];
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [color, setColor] = useState("#c0c0c0")
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getProduct(id) {
            try {
                const product = await axios.post(`${baseApiUrl}/specificProduct`, {
                    productID: id,
                });
                setSpecificProduct(product?.data?.[0]?.[0]);
                handleBlurOnImage();
            } catch (e) {
                console.error("Error fetching product", e);
            } finally {
                setLoading(false); // Stop the loader
            }
        }
        getProduct(id);
    }, [largeImage]);





    useEffect(() => {
        if (cookies.role == "Administrator") {
            axios.get(`${baseApiUrl}/offers`).then(rez => {
                var offers = rez.data[0]
                for (var i = 0; i < offers.length; i++) {
                    if (offers[i].ProductID == id) {
                        setIsProductInOffer(true)
                        break;
                    }
                }

            })
        }
    }, [])

    function handleValueChange(e) {
        let newValue = e.target.value;
        setQuantity(newValue);
    }

    function handleImageChange(e) {
        setLargeImage(e.target.id);
        handleBlurOnImage();

    }

    function handleBlurOnImage() {
        const allImages = document.querySelectorAll("img");
        allImages?.forEach((img) => {
            if (img?.id != largeImage) {
                img?.classList?.add(style.imageBlurEffect);
            } else {
                img?.classList?.remove(style.imageBlurEffect);
            }
        });
    }

    function handleDeleteProduct() {
        axios
            .delete(`${baseApiUrl}/deleteProduct/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .then((rez) => {
                document.getElementById("informWindow").classList.add("d-none");
                document.getElementById("footer").classList.add("d-none");
                document.getElementById("closeWindow").classList.remove("d-none");
            });
    }

    function handleAddToDashboard() {

        var doesExistProductOnDashboard = false;
        for (let prod = 0; prod < productsOnDashboard.length; prod++) {
            if (
                productsOnDashboard[prod].id == id &&
                productsOnDashboard[prod].quantity != quantity
            ) {
                productsOnDashboard[prod] = {
                    id: id,
                    quantity: quantity,
                };
                doesExistProductOnDashboard = true;
                break;
            } else if (productsOnDashboard[prod].id == id) {
                doesExistProductOnDashboard = true;
                break;
            } else {
            }
        }

        if (doesExistProductOnDashboard == false) {
            productsOnDashboard.unshift({
                id: id,
                quantity: quantity,
            });
            updateProductToDashboard(productsOnDashboard.length);
            window.scrollTo({
                top: 40,
                behavior: "smooth",
            });
            navigate("/dashboard")
        }
    }

    const formik = useFormik({
        initialValues: {
            ProductID: specificProduct?.ProductID,
            ProductName: specificProduct?.ProductName,
            CategoryName: specificProduct?.CategoryName,
            BrandName: specificProduct?.BrandName,
            Price: specificProduct?.Price,
            StockQuantity: specificProduct?.StockQuantity,
            Weight: specificProduct?.Weight,
            Rating: specificProduct?.Rating,
            gender: specificProduct?.gender,
            organizationPrice: specificProduct?.organizationPrice,
            offerPrice: specificProduct?.offerPrice,
        },
        onSubmit: (values) => {
            setChangedProduct([]);
            var chProducts = [];
            for (let item in values) {
                if (values[item] != null) {
                    chProducts.push({ item, value: values[item] });
                }
                if (values[item] == undefined) {
                    // values[item] = specificProduct?.[item]
                }
            }
            setChangedProduct(chProducts);
        },
    });

    function handleButtonAfterDelete() {
        navigate("/");
    }

    function handleUpdateProduct() {
        axios.put(`${baseApiUrl}/updatePrice/${id}/price`, {
            changedProducts
        }, {
            headers: {
                'Authorization': cookies["token"]
            }
        }).then(rez => {
            document.getElementById("updateInfo").classList.add("d-none");
            document.getElementById("footerUpdate").classList.add("d-none");
            document.getElementById("updatCloseWindow").classList.remove("d-none");
        })

        //qetu duhet me thirr endpointin qe e bon update produktin
    }

    function handleButtonAfterUpdate() {
        navigate(`/product/${id}`);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0]; //file qe me vyn
        setSelectedFile(e.target.files[0]);
        displayPhotoInButton(e.target.files[0]);

        const reader = new FileReader();
        reader.onload = async (event) => {
            const imageUrl = event.target.result;
            const imageFormData = new FormData();
            imageFormData.append('images', file);
            imageFormData.append('product_id', id);
            const uploadResponse = await axios.post(`${baseApiUrl}/upload`, imageFormData, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status !== 200) {
                throw new Error('Failed to upload images');
            }


        };
        reader.readAsDataURL(file);

    };

    function saveImage() {

    }

    const displayPhotoInButton = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            // Update the background image of the img-view div
            document.getElementById("img-view").style.backgroundImage = `url(${event.target.result})`;
        };
        reader.readAsDataURL(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file) {
            setSelectedFile(file);
            displayPhotoInButton(file);

            const reader = new FileReader();
            reader.onload = async (event) => {
                const imageUrl = event.target.result;
                const imageFormData = new FormData();
                imageFormData.append('images', file);
                imageFormData.append('product_id', id);
                const uploadResponse = await axios.post(`${baseApiUrl}/upload`, imageFormData, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (uploadResponse.status !== 200) {
                    throw new Error('Failed to upload images');
                }

            };
            reader.readAsDataURL(file);
        }
    };

    //Customization of name
    function writeName(e) {
        const val = e.target.value
        setName(val)
    }
    function handleFontName(e) {
        const computedStyles = window.getComputedStyle(e.target);
        const fontFamily = computedStyles.fontFamily;
        setFontName(fontFamily)
    }


    function handleAddOffer(e) {
        axios.post(
            `${baseApiUrl}/addOffer`,
            { 
                "product_id": id // Data goes here
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token // Ensure token is correctly defined
                }
            }
        ).then((rez) => {
            setIsProductInOffer(true);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    function handleRemoveOffer(e) {
        axios.delete(`${baseApiUrl}/removeOffer/${id}`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(rez => {})
        setIsProductInOffer(false)

    }

    if (loading) {
        return <div style={{ marginTop: "300px" }}>
            <LoadingIndicator size={100} />
        </div> // Replace with a loader spinner or component
    }


    return (
        <div style={{}}>
            <hr className={`${style.hline}`} ></hr>

            <div className={`${style.main} container`} style={{}} onDragOver={handleDragOver} onDrop={handleDrop}>
                <div className={`${style.image}`}>
                    <div className={`${style.largeImage}`} style={{ cursor: "pointer" }}>
                        <img
                            id={largeImage}
                            src={imageArray?.[largeImage]}
                            onClick={handleImageChange}
                            style={{ width: "100%", height: "100%", opacity: "1" }}
                        ></img>
                    </div>
                    <div className={`${style.allImages}`}>
                        {imageArray?.map((prod) => (
                            <img
                                id={imageArray.indexOf(prod)}
                                key={imageArray.indexOf(prod)}
                                src={prod}
                                onClick={handleImageChange}
                                className={`${style.specificImage}`}
                            ></img>
                        ))}
                        <div style={{ display: cookies.role == "Administrator" ? "" : "none" }} className={`${style.specificImage} ${style.addNewPhoto}`}>
                            <label htmlFor="input-file" id="drop-areea" style={{ width: "100%", height: "100%" }}>
                                <input type="file" id="input-file" hidden onChange={handleFileChange}></input>
                                <div id="img-view" style={{ width: "100%", height: "100%", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                            </label>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div className={`${style.description}`}>
                    <h3>{specificProduct?.ProductName}</h3>
                    <span className={`${style.hlinee}`}></span>

                    <div className={`${style.specifics}`}>
                        <table className={`table table-striped `} style={{ width: "95%", marginTop: "20px" }}>
                            <tbody>
                                <tr className={`${style.row}`}>
                                    <td className={`${style.categoryDescription}`}>{t("specificProduct.emriIProduktit")}</td>
                                    {(cookies.role != "Administrator") ? (
                                        <td className={`${style.productDescription}`}>{specificProduct?.ProductName}</td>
                                    ) : (
                                        <td className={`${style.productDescription}`}>
                                            <td className={`${style.productDescription}`}>{specificProduct?.ProductName}</td>
                                        </td>
                                    )}
                                </tr>
                                <tr className={`${style.row}`}>
                                    <td className={`${style.categoryDescription}`}>{t("specificProduct.cmimi")}</td>
                                    {(cookies.role != "Administrator") ? (
                                        <td className={style.productDescription}>
                                            <span>
                                                {specificProduct && specificProduct.sellingPrice !== undefined
                                                    ? specificProduct.
                                                        sellingPrice.toFixed(2) + " euro"
                                                    : 'Price not available'}
                                            </span>
                                            <span style={{ textDecoration: "line-through", marginLeft: "10px", color: "red" }}>
                                                {specificProduct.offerPrice !== undefined && specificProduct.offerPrice !== null && specificProduct.offerPrice !== 0
                                                    ? `${specificProduct.offerPrice?.toFixed(2)} euro `
                                                    : ""}
                                            </span>
                                        </td>) : (
                                        <td className={`${style.productDescription}`}>
                                            <span>{specificProduct.
                                                sellingPrice.toFixed(2)} euro
                                            </span>
                                            <input type="text" name="Price" onChange={formik.handleChange} defaultValue={specificProduct?.Price?.toFixed(2)}></input>
                                        </td>
                                    )}
                                </tr>
                                <tr className={`${style.row}`}>
                                    <td className={`${style.categoryDescription}`}>{t("specificProduct.kategoria")}</td>
                                    {(cookies.role != "Administrator") ? (
                                        <td className={`${style.productDescription}`}>{specificProduct?.gender}</td>
                                    ) : (
                                        <td className={`${style.productDescription}`}>
                                            <td className={`${style.productDescription}`}>{specificProduct?.gender}</td>
                                        </td>
                                    )}
                                </tr>
                                <tr style={{ display: cookies.role != "Administrator" ? "none" : "" }} className={`${style.row}`}>
                                    <td className={`${style.categoryDescription}`}>Offer Price</td>
                                    <td className={`${style.productDescription}`}>
                                        <span>{specificProduct.
                                            offerPrice?.toFixed(2)} 
                                        </span>
                                        <input type="text" name="offerPrice" onChange={formik.handleChange} defaultValue={specificProduct?.offerPrice?.toFixed(2)}></input>

                                    </td>
                                </tr>
                                <tr style={{ display: cookies.role != "Administrator" ? "none" : "" }} className={`${style.row}`}>
                                    <td className={`${style.categoryDescription}`}>ORGP</td>
                                    <td className={`${style.productDescription}`}>
                                        <span>{specificProduct.organizationPrice?.toFixed(2)} 
                                        </span>
                                        <input type="text" name="organizationPrice" onChange={formik.handleChange} defaultValue={specificProduct?.organizationPrice?.toFixed(2)}></input>

                                    </td>
                                </tr>
                                <tr className={`${style.row}`}>
                                    <td className={`${style.categoryDescription}`}>{t("specificProduct.brendi")}</td>
                                    {(cookies.role != "Administrator") ? (
                                        <td className={`${style.productDescription}`}>{specificProduct?.BrandName}</td>
                                    ) : (
                                        <td className={`${style.productDescription}`}>
                                            <td className={`${style.productDescription}`}>{specificProduct?.BrandName}</td>

                                        </td>
                                    )}
                                </tr>
                                <tr style={{ display: specificProduct?.CategoryName == "Personalizime" ? "none" : "" }}>
                                    <td className={`${style.categoryDescription}`}>{t("specificProduct.stoku")}</td>
                                    {(cookies.role != "Administrator") ? (
                                        <td>
                                            <input
                                                className={`${style.input}`}
                                                type="number"
                                                min="1"
                                                defaultValue={quantity}
                                                step="1"
                                                onChange={handleValueChange}
                                                max={specificProduct?.StockQuantity}
                                            />
                                        </td>
                                    ) : (
                                        <td>
                                            {" "}
                                            <input className={`${style.input}`} type="number" min="1" defaultValue={quantity} step="1" onChange={handleValueChange} />
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        <div className={`${style.acord}`}>
                            {(cookies.role == "Administrator") ? (
                                <div style={{ width: "50%", display: "flex", marginRight: "5px" }}>
                                    <button className={`btn ${style.button} w-100`} style={{ backgroundColor: "#771923" }} data-bs-target="#delete" data-bs-toggle="modal">
                                        Fshini produktin
                                    </button>
                                    <div class="modal fade" id="delete" style={{ zIndex: "100000" }}>
                                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                            <div class="modal-content">
                                                <div class="modal-header" style={{ backgroundColor: "white" }}>
                                                    <button class="btn-close" data-bs-dismiss="modal" style={{ backgroundColor: "white", color: "red" }}></button>
                                                </div>
                                                <div class="modal-body" style={{ backgroundColor: "white", color: "#771923", fontSize: "200px", minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div id="informWindow">
                                                        <i class="bi bi-trash"></i>
                                                        <h3 style={{ color: "black" }}>Produkti i fshire nuk mund te kthehet!</h3>
                                                    </div>
                                                    <div className="d-none" id="closeWindow">
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                                            <h3 style={{ color: "black" }}>
                                                                Produkti u fshie me sukses<span style={{ color: "green" }}>
                                                                    <i class="bi bi-check-circle-fill"></i>
                                                                </span>
                                                            </h3>
                                                            <button class="btn " style={{ backgroundColor: "#771923", color: "white", marginTop: "30px" }} onClick={handleButtonAfterDelete} data-bs-dismiss="modal">
                                                                Mbylleni
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer" id="footer" style={{ backgroundColor: "white", display: "flex", justifyContent: "center" }}>
                                                    <button class="btn " style={{ backgroundColor: "green", color: "white" }} data-bs-dismiss="modal">
                                                        Anulo Fshijen
                                                    </button>
                                                    <button class="btn " style={{ backgroundColor: "#771923", color: "white" }} onClick={handleDeleteProduct}>
                                                        Fshije Produktin
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            {(cookies.role != "Administrator") ? (
                                <button className={`btn ${style.button}`} style={{ maxHeight: "72px", backgroundColor: "#771923", marginLeft: "5px" }} onClick={handleAddToDashboard}>
                                    {t("specificProduct.shtoNeShporte")}
                                </button>
                            ) : (
                                <div>
                                    <button data-bs-toggle="modal" data-bs-target="#update" className={`btn ${style.button}`} style={{ backgroundColor: "green", marginLeft: "5px", width: "100%" }} onClick={formik.handleSubmit}>
                                        Ruaj ndryshimet
                                    </button>
                                    <div class="modal fade" id="update" style={{ zIndex: "100000" }}>
                                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ minWidth: "40%" }}>
                                            <div class="modal-content">
                                                <div class="modal-header" style={{ backgroundColor: "white" }}>
                                                    <button class="btn-close" data-bs-dismiss="modal" style={{ backgroundColor: "white", color: "red" }}></button>
                                                </div>
                                                <div class="modal-body" style={{ backgroundColor: "white", color: "#771923", fontSize: "20px", minHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <table className="table table-hover table-striped" id="updateInfo">
                                                        <thead>
                                                            <tr>
                                                                <th>Pershkrimi</th>
                                                                <th>Pershkrimi ParaPrak</th>
                                                                <th>Pershkrimi i ri</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {changedProducts.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.item}</td>
                                                                    <td>{specificProduct[item.item]}</td>
                                                                    <td>{item.value}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div className="d-none" id="updatCloseWindow">
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                                            <h3 style={{ color: "black" }}>
                                                                Produkti u perditesua me sukses<span style={{ color: "green" }}>
                                                                    <i class="bi bi-check-circle-fill"></i>
                                                                </span>
                                                            </h3>
                                                            <button class="btn " style={{ backgroundColor: "#771923", color: "white", marginTop: "30px" }} onClick={handleButtonAfterUpdate} data-bs-dismiss="modal">
                                                                Mbylleni
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer" id="footerUpdate" style={{ backgroundColor: "white", display: "flex", justifyContent: "center" }}>
                                                    <button class="btn " style={{ backgroundColor: "green", color: "white" }} data-bs-dismiss="modal">
                                                        Anulo Perditesimin
                                                    </button>
                                                    <button disabled={changedProducts.length == 0} class="btn " style={{ backgroundColor: "#771923", color: "white" }} onClick={handleUpdateProduct}>
                                                        Perditeso
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={{ display: cookies.role == "Administrator" ? "flex" : "none", marginTop: "20px", justifyContent: "space-between" }}>
                            <button className="btn btn-secondary" id="addOffer" disabled={isProductInOffer ? true : false} onClick={handleAddOffer}>Shto Oferte</button>
                            <button className="btn btn-warning" id="removeOffer" disabled={isProductInOffer ? false : true} onClick={handleRemoveOffer}>Largo Oferten</button>
                        </div>
                    </div>
                </div>


            </div >
            <div style={{ display: (specificProduct?.description == "" ? "none" : "") }}>
                <h3 style={{ marginTop: "20px" }}> {specificProduct?.ProductName} </h3>
                <div className={` ${style.productDesc} container`}>
                    <div dangerouslySetInnerHTML={{ __html: specificProduct?.description }} />
                </div>
            </div>
        </div>
    );
}