import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import baneri from "./banner.module.css";
import axios from "axios";
import parfimat from "./parfimat1.png";
import shampoo from "./shampoo.png";
import honey from "./honey1.png";
import oils from "./oils2.png";
import krema from "./krema1.png";

export default function Banneri() {
    const [products, setProducts] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Save the window width

    useEffect(() => {
        fetchData();

    }, []);

    useEffect(() => {
        // Function to update the state with the current window width
        const handleResize = () => setWindowWidth(window.innerWidth);
    
        // Add the resize event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    const images = [
        { src: parfimat, alt: "Parfimat Image", width: windowWidth>600 ? "50%":"160%", height:windowWidth>600 ? "50%":"160%" },
        { src: shampoo, alt: "Shampoo Image",width: windowWidth>600 ? "50%":"140%", height:windowWidth>600 ? "50%":"140%" },
        { src: honey, alt: "Honey Image", width: windowWidth>600 ? "80%":"160%", height:windowWidth>600 ? "80%":"160%"  },
        { src: oils, alt: "Oils Image", width: windowWidth>600 ? "80%":"180%", height:windowWidth>600 ? "80%":"180%"  },
        { src: krema, alt: "Krema Image", width: windowWidth>600 ? "50%":"180%", height:windowWidth>600 ? "50%":"180%"  }
    ];


    function fetchData() {
        axios.get(`${baseApiUrl}/offers`).then(rez => {
            const filteredProducts = rez.data[0].map(prod => ({
                ...prod,
                ProductImages: prod.ProductImages || ''  // Ensure ProductImages is not null or undefined
            }));
            setProducts(filteredProducts);
        }).catch(error => console.error('Failed to fetch products:', error));
    }

    function handleSlide(index) {
        setActiveIndex(index);
    }

    return (
        <div className={`container-fluid position-relative ${baneri.main}`} style={{ width: "100%" }}>
            <div id="banner" className="carousel carousel-dark" style={{ width: "100%" }} data-bs-ride="carousel">
                <div className="carousel-inner" style={{ zIndex: "0" }}>
                    {images.map((image, index) => (
                        <div className={`carousel-item w-100 ${index === activeIndex ? "active" : ""}`} key={index} data-bs-interval="3000">
                            <div className={` ${baneri.backroundImage}`}>
                                <img src={image.src} style={{ minWidth:image.width, minHeight:image.height, objectFit: "contain" }} ></img>
                            </div>
                        </div>
                    ))}
                </div>
                <button className={`carousel-control-prev ${baneri.buton}`} style={{ width: "60px", zIndex: "0" }} data-bs-slide="prev" data-bs-target="#banner"><span className="carousel-control-prev-icon"></span></button>
                <button className={`carousel-control-next ${baneri.buton}`} style={{ width: "60px", zIndex: "0" }} data-bs-slide="next" data-bs-target="#banner"><span className="carousel-control-next-icon"></span></button>
                <div className="carousel-indicators">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            className={index === activeIndex ? "active" : ""}
                            data-bs-slide-to={index}
                            data-bs-target="#banner"
                            style={{ backgroundColor: index === activeIndex ? "#771923" : "#771923" }}
                            onClick={() => handleSlide(index)}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
    );
}
