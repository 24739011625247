import React, { useState } from 'react';
import { Button, Spinner, Alert } from 'react-bootstrap';
import styles from './addProductForm.module.css';
import axios from 'axios';
import LoadingIndicator from 'react-loading-indicator';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// const kremaMap = new Map([["krema", ["fytyr"]]]);
// const shampoMap = new Map([["shampo", ["sapun"]]]);
// const vajraMap = new Map([["vajra", ["vendore", "importuara"]]]);
// const parfumeMap = new Map([["parfume", ["meshkuj", "femra"]]]);
// const mjalteMap = new Map([["mjalte", ["shtimPeshe", "humbjePeshe"]]]);
// const cajraMap = new Map([["cajra", ["shtimPeshe", "humbjePeshe"]]]);
const fytyr = ["krema"];
const sapun = ["floke"];
const thinjura = ["floke"];
const shampo = ["floke"];
const bojra = ["floke"];
const vendore = ["vajra"];
const importuara = ["vajra"];
const meshkuj = ["parfume"];
const dyGjinit = ["parfume"];
const femra = ["parfume"];
const shtimPeshe = ["mjalte", "cajra"];
const humbjePeshe = ["mjalte", "cajra"];


const AddProductForm = () => {
  const [cookies] = useCookies(['token']);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('danger');
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const formik = useFormik({
    initialValues: {
      productName: '',
      organizationPrice: '',
      sellingPrice: '',
      offerPrice: '',
      stockQuantity: '10',
      categoryName: '',
      brandName: '--',
      gender: '',
      organizationName: '',
      productDescription: '',
    },
    validationSchema: Yup.object({
      productName: Yup.string().required('Required'),
      organizationPrice: Yup.number().required('Required').positive('Must be positive'),
      sellingPrice: Yup.number().required('Required').positive('Must be positive'),
      offerPrice: Yup.number(),
      stockQuantity: Yup.number().required('Required').min(1, 'Must be at least 1'),
      categoryName: Yup.string(),
      brandName: Yup.string().required('Required'),
      gender: Yup.string().required('Required'),
      organizationName: Yup.string().required('Required'),
      productDescription: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setShowAlert(false);

      const formData = {
        ...values,
        organizationPrice: parseFloat(values.organizationPrice),
        sellingPrice: parseFloat(values.sellingPrice),
        offerPrice: parseFloat(values.offerPrice),
        stockQuantity: parseInt(values.stockQuantity),
      };

      try {
        const token = Cookies.get('token');
        if (!token) {
          throw new Error('No token found in cookies');
        }

        const response = await axios.post(`${baseApiUrl}/insertProduct`, formData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        const responseData = response.data;
        const productId = responseData.productId;

        const imageFormData = new FormData();
        images.forEach((image) => {
          imageFormData.append('images', image);
        });
        imageFormData.append('product_id', productId);

        const uploadResponse = await axios.post(`${baseApiUrl}/upload`, imageFormData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (uploadResponse.status !== 200) {
          throw new Error('Failedd');
        }

        formik.resetForm();
        setImages([]);
        setAlertMessage('Produkti u shtua me sukses!');
        setShowAlert(true);
        setAlertVariant('success');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setAlertMessage('Not authorized');
          setShowAlert(true);
          setAlertVariant('danger');
        } else {
          setAlertMessage('Ndodhi një gabim! Ju lutem provoni përsëri.');
          setShowAlert(true);
          setAlertVariant('danger');
          console.error('Error:', error);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className={`${styles.main} container-fluid`} style={{ width: "100%" }}>
      <h2 className={`${styles.text}`}>Shto një produkt</h2>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        {loading && <LoadingIndicator />}
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Emri produktit:</label>
          <input
            type="text"
            className={styles.inputField}
            {...formik.getFieldProps('productName')}
          />
          {formik.touched.productName && formik.errors.productName ? (
            <div style={{ color: "red" }}>{formik.errors.productName}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Çmimi i organizatës:</label>
          <input
            type="number"
            className={styles.inputField}
            {...formik.getFieldProps('organizationPrice')}
          />
          {formik.touched.organizationPrice && formik.errors.organizationPrice ? (
            <div style={{ color: "red" }}>{formik.errors.organizationPrice}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Çmimi i shitjes:</label>
          <input
            type="number"
            className={styles.inputField}
            {...formik.getFieldProps('sellingPrice')}
          />
          {formik.touched.sellingPrice && formik.errors.sellingPrice ? (
            <div style={{ color: "red" }}>{formik.errors.sellingPrice}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Çmimi i ofertës<span style={{ fontWeight: "bold" }}>(line-through)</span>:</label>
          <input
            type="number"
            className={styles.inputField}
            {...formik.getFieldProps('offerPrice')}
          />
          {formik.touched.offerPrice && formik.errors.offerPrice ? (
            <div style={{ color: "red" }}>{formik.errors.offerPrice}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Sasia në stok:</label>
          <input
            type="number"
            className={styles.inputField}
            {...formik.getFieldProps('stockQuantity')}
          />
          {formik.touched.stockQuantity && formik.errors.stockQuantity ? (
            <div style={{ color: "red" }}>{formik.errors.stockQuantity}</div>
          ) : null}
        </div>
        <div style={{ marginLeft: "-150px" }} className={styles.inputRow}>
          {/* <label className={`form-label ${styles.data}`}>Kategoria:</label> */}
          <div className={`${styles.inputField} ${styles.genderRadioGroup}`}>
            <label>
              <input
                type="radio"
                value="krema"
                checked={formik.values.gender === 'krema'}
                onChange={() => formik.setFieldValue('gender', 'krema')}
                required
              />
              Krema
            </label>
            <label>
              <input
                type="radio"
                value="floke"
                checked={formik.values.gender === 'floke'}
                onChange={() => formik.setFieldValue('gender', 'floke')}
                required
              />
              Floke
            </label>
            <label>
              <input
                type="radio"
                value="vajra"
                checked={formik.values.gender === 'vajra'}
                onChange={() => formik.setFieldValue('gender', 'vajra')}
                required
              />
              Vajra
            </label>
            <label>
              <input
                type="radio"
                value="parfume"
                checked={formik.values.gender === 'parfume'}
                onChange={() => formik.setFieldValue('gender', 'parfume')}
                required
              />
              Parfume
            </label>
            <label>
              <input
                type="radio"
                value="mjalte"
                checked={formik.values.gender === 'mjalte'}
                onChange={() => formik.setFieldValue('gender', 'mjalte')}
                required
              />
              Mjalte
            </label>
            <label>
              <input
                type="radio"
                value="kapsula"
                checked={formik.values.gender === 'kapsula'}
                onChange={() => formik.setFieldValue('gender', 'kapsula')}
                required
              />
              Kapsula
            </label>
            <label>
              <input
                type="radio"
                value="cajra"
                checked={formik.values.gender === 'cajra'}
                onChange={() => formik.setFieldValue('gender', 'cajra')}
                required
              />
              Cajra
            </label>
          </div>
          {formik.touched.gender && formik.errors.gender ? (
            <div style={{ color: "red" }}>{formik.errors.gender}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Brendi:</label>
          <input
            type="text"
            className={styles.inputField}
            {...formik.getFieldProps('brandName')}
          />
          {formik.touched.brandName && formik.errors.brandName ? (
            <div style={{ color: "red" }}>{formik.errors.brandName}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Nenkategoria:</label>
          <div className={`${styles.inputField} ${styles.genderRadioGroup}`}>
            <label style={{ display: fytyr.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="fytyr"
                checked={formik.values.categoryName === 'fytyr'}
                onChange={() => formik.setFieldValue('categoryName', 'fytyr')}
              />
              Fytyr
            </label>
            <label style={{ display: sapun.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="sapun"
                checked={formik.values.categoryName === 'sapun'}
                onChange={() => formik.setFieldValue('categoryName', 'sapun')}
              />
              Sapun
            </label>
            <label style={{ display: shampo.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="shampo"
                checked={formik.values.categoryName === 'shampo'}
                onChange={() => formik.setFieldValue('categoryName', 'shampo')}
              />
              Shampo
            </label>
            <label style={{ display: thinjura.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="thinjura"
                checked={formik.values.categoryName === 'thinjura'}
                onChange={() => formik.setFieldValue('categoryName', 'thinjura')}
              />
              Thinjura
            </label>
            <label style={{ display: bojra.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="bojra"
                checked={formik.values.categoryName === 'bojra'}
                onChange={() => formik.setFieldValue('categoryName', 'bojra')}
              />
              Bojra
            </label>
            <label style={{ display: meshkuj.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="meshkuj"
                checked={formik.values.categoryName === 'meshkuj'}
                onChange={() => formik.setFieldValue('categoryName', 'meshkuj')}
              />
              Meshkuj
            </label>
            <label style={{ display: femra.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="femra"
                checked={formik.values.categoryName === 'femra'}
                onChange={() => formik.setFieldValue('categoryName', 'femra')}
              />
              Ferma
            </label>
            <label style={{ display: dyGjinit.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="dyGjinit"
                checked={formik.values.categoryName === 'dyGjinit'}
                onChange={() => formik.setFieldValue('categoryName', 'dyGjinit')}
              />
              Dy Gjnit
            </label>
            <label style={{ display: shtimPeshe.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="shtimPeshe"
                checked={formik.values.categoryName === 'shtimPeshe'}
                onChange={() => formik.setFieldValue('categoryName', 'shtimPeshe')}
              />
              ShtimPeshe
            </label>
            <label style={{ display: humbjePeshe.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="humbjePeshe"
                checked={formik.values.categoryName === 'humbjePeshe'}
                onChange={() => formik.setFieldValue('categoryName', 'humbjePeshe')}
              />
              HumbjePeshe
            </label>
            <label style={{ display: vendore.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="vendore"
                checked={formik.values.categoryName === 'vendore'}
                onChange={() => formik.setFieldValue('categoryName', 'vendore')}
              />
              Vendore
            </label>
            <label style={{ display: importuara.includes(formik.values.gender) ? "inline" : "none" }}>
              <input
                type="radio"
                value="importuara"
                checked={formik.values.categoryName === 'importuara'}
                onChange={() => formik.setFieldValue('categoryName', 'importuara')}
              />
              Importuara
            </label>

          </div>
          {formik.touched.categoryName && formik.errors.categoryName ? (
            <div style={{ color: "red" }}>{formik.errors.categoryName}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Emri organizatës:</label>
          <div className={`${styles.inputField} ${styles.genderRadioGroup}`}>
            <label>
              <input
                type="radio"
                value="Ikra"
                checked={formik.values.organizationName === 'Ikra'}
                onChange={() => formik.setFieldValue('organizationName', 'Ikra')}
              />
              Ikra
            </label>
            <label>
              <input
                type="radio"
                value="Al-Jibab"
                checked={formik.values.organizationName === 'Al-Jibab'}
                onChange={() => formik.setFieldValue('organizationName', 'Al-Jibab')}
              />
              Al-Jibab
            </label>
          </div>
          {formik.touched.organizationName && formik.errors.organizationName ? (
            <div style={{ color: "red" }}>{formik.errors.organizationName}</div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Fotot:</label>
          <div className={styles.inputField}>
            <input name='' type="file" multiple onChange={handleImageChange} />
          </div>
          {Array.isArray(images) && images.map((image, index) => (
            <div key={index} className={`${styles.imageDiv} mt-2`}>
              <img src={URL.createObjectURL(image)} alt={`Image ${index}`} width="100" height="100" />
            </div>
          ))}
        </div>
        {showAlert && <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>{alertMessage}</Alert>}
        <div className={styles.inputRow}>
          <label className={`form-label ${styles.data}`}>Pershkrimi produktit:</label>
          <textarea
            style={{ width: '600px', height: '200px' }}
            className={styles.inputField}
            {...formik.getFieldProps('productDescription')}
          />
          {formik.touched.productDescription && formik.errors.productDescription ? (
            <div>{formik.errors.productDescription}</div>
          ) : null}
        </div>
        <div className={`${styles.buttonDiv}`}>
          <button type="submit" className={`${styles.link}`} disabled={loading}>
            Shto produktin
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProductForm;
