import style from "./Footer.module.css"
// import icona from "../../photos/icona.PNG"
// import image from "../../photos/footerIcon.png"
import icona from "../../photos/background3.png"

export default function Footer() {


    return (
        <div style={{ backgroundColor: "#771923", marginTop: "80px", marginBottom: "0" }}>
            <div className={`${style.row}`} style={{ height: "250px" }}>
                <div className={` ${style.icons}`}>
                    <span className={`${style.specifcIcon}`}><a href="https://www.facebook.com/profile.php?id=61570177539728" target="_blank"><i className="bi bi-facebook" style={{color:"white"}} ></i></a></span>
                    <span className={`${style.specifcIcon}`}><a href="https://www.tiktok.com/@bioprod1?lang=en" target="_blank"><i className="bi bi-tiktok" style={{color:"white"}} ></i></a></span>
                    <span className={`${style.specifcIcon}`}><a href="https://www.instagram.com/bioprod11/?next=%2F" target="_blank"><i className="bi bi-instagram" style={{color:"white"}} ></i></a></span>

                </div>
                <div className={` ${style.image}`}>
                    <img src={icona} style={{ opacity: "0.7", width:"180px" }}></img>
                    <div style={{marginTop:"10px"}}>
                        <span className={``} ><i className={`bi bi-telephone  `} ></i> +383 45 866 269</span>
                        <span className={` `} style={{ whiteSpace: "nowrap" }}><i className={`bi bi-envelope  `}></i>bprodukti@gmail.com</span>
                    </div>
                </div>
                <div className={` ${style.subscribe}`}>
                    <div className="" style={{ width: "fit-content" }}>
                        <p style={{ textAlign: "middle", fontSize: "22px" }}>Abonohu per me te rejat</p>
                        <div className={`input-group input-group ${style.group}`} style={{ whiteSpace: "nowrap", display:"flex", justifyContent:"center", marginBottom:"10px" }} >
                            <input className={`${style.inputFild}`} type="text"  placeholder="Emaili juaj " style={{ backgroundColor: "transparent", color: "white", maxWidth:"50%" }} />
                            <button className="btn" style={{ backgroundColor: "white" }}>Get started</button>
                        </div>
                    </div>


                </div>
            </div>
            <div className={`${style.hline}`}></div>
            <div style={{ color: "white", textAlign: "end", width: "90%", margin: "auto", marginTop: "8px", fontSize: "18px", }}><a href="https://www.facebook.com/profile.php?id=61561486274957" target="_blank" style={{color:"white", textDecoration:"none"}}><p>Web support by AnSy</p></a></div>
        </div>
    )
}